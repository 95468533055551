export default {
  methods: {
    isUrlExternal(url) {
      const router = useRouter()
      if (url === null || url === undefined) return false
      return router.resolve(url?.split('#').shift()).name === null
    },

    getProUrl(slug = '') {
      if (import.meta.client) {
        if (window.location.host.includes('www')) {
          return `${window.location.protocol}//${window.location.host.replace('www', 'pro')}/${slug}`
        } else if (window.location.host?.split('.')[0] !== 'pro') {
          return `${window.location.protocol}//pro.${window.location.host}/${slug}`
        } else {
          return `${window.location.protocol}//${window.location.host}/${slug}`
        }
      } else {
        return '/'
      }
    },
  },
}
